import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDniart2KdFIDKMnpDMx7zixkOMmHBL_hQ",
  authDomain: "pitch-point-me.firebaseapp.com",
  databaseURL: "https://pitch-point-me-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pitch-point-me",
  storageBucket: "pitch-point-me.appspot.com",
  messagingSenderId: "864508459972",
  appId: "1:864508459972:web:5e4f5f125f4fc731fe8291",
  measurementId: "G-YLSM20XNWF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

function addSubscriber(name, email, phone) {
  const subscribersRef = ref(database, 'subscribers');
  const newSubscribersRef = push(subscribersRef);

  return set(newSubscribersRef, {
    name,
    email,
    phone
  });
}

export { addSubscriber };