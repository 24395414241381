import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/css/themify-icons.css';
import './assets/css/index.css';

import $ from "jquery";
import WOW from 'wow.js';
import 'bootstrap';
import { addSubscriber } from './assets/js/firebase-init.js';



$(function() {  
  // Page animation initialize
  new WOW().init();
  
  var backTop = $(".btn-back_to_top");
  var facebookIcon = $("#facebook-icon");
  var instagramIcon = $("#instagram-icon");
  
  $(window).on('scroll', function() {
    if($(document).scrollTop() > 400) {
      backTop.css('visibility', 'visible');
    }
    else if($(document).scrollTop() < 400) {
      backTop.css('visibility', 'hidden');
    }
  });
  
  backTop.on('click', function() {
    $('html').animate({
      scrollTop: 0
    }, 1);
    return false;
  });

  facebookIcon.on('click', function() {
    window.open('https://www.facebook.com/PitchPoint.me/', '_blank');
  });
  instagramIcon.on('click', function() {
    window.open('https://www.instagram.com/pitchpoint.me/', '_blank');
  });
});

$(function() {  
  $('[data-animate="scrolling"]').each(function() {
    var self = $(this);
    var target = $(this).data('target') ? $(this).data('target') : $(this).attr('href');
    
    self.on('click', function(e) {
      $('body, html').animate({ scrollTop: $(target).offset().top }, 100);
      return false;
    });
  });
});

$(function() {
  $('#contact_form').on('submit', function(e) {
      e.preventDefault();
      var data = $("#contact_form :input").serializeArray();
      var name = '';
      var email = '';
      var phone = '';

      data.forEach(input => {
        switch (input.name) {
          case 'name':
            name = input.value;
          break;
          case 'phone':
            phone = input.value;
          break;
          case 'email':
            email = input.value;
          break;
        }
      });

      addSubscriber(name, email, phone)
        .then(() => {
          $('#contact_form')[0].reset();
          $('#contact_form').hide();
          $('.contact_send_success').show();
        });
  });
});